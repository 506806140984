<template>
  <div class="serve">
    <div class="container">
      <div class="banner">
        <div class="auto-container">
          <h1>定制服务</h1>
          <p><a href="/">首页</a>
            <Icon
              class="arrow"
              type="ios-arrow-forward"
            />公司
            <Icon
              class="arrow"
              type="ios-arrow-forward"
            />定制服务
          </p>
        </div>
      </div>
      <div class="auto-container">
        <div class="info">
          <Tabs
            :value="menuId"
            @on-click="setmenuId"
          >
            <TabPane
              :label="item.title"
              v-for="(item, index) in navList"
              :key="index"
              :name="`${item.serId}`"
            >

            </TabPane>
          </Tabs>

          <Row :gutter="30">
            <Col
              :xl="8" :lg="8" :md="12" :sm="24"
              v-for="(item,index) in newList"
              :key="index"
            >
            <!-- 点击跳转详情页 @click="toContant(item.code)" -->
            <div class="inner-box">
              <div class="image">
                <a href="#"><img
                    :src="item.wechatPic?item.wechatPic:defaultImg"
                    alt=""
                  /></a>
              </div>
              <div class="lower-content">
                <!-- <ul class="post-meta">
                  <li>{{item.time}}</li>
                  <li>{{item.info}}</li>
                </ul> -->
                <h3><a href="#">{{item.title}}</a></h3>
                <div class="text">{{item.text}}</div>
                <!-- <a
                  href="#"
                  class="read-more"
                >Read More
                  <Icon
                    class="arrow"
                    type="ios-arrow-round-forward"
                  />
                </a> -->
              </div>
            </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import serve from "./index.js";
export default serve;
</script>

<style lang="less" scoped>
@import "./index.less";
</style>